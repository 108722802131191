<template>
  <base-material-card
    id="customer-list"
    :title="$t('list', { name: $tc('customer', 2) })"
    icon="mdi-account"
  >
    <v-row align="center" class="pa-md-3">
      <v-col
        cols="8"
        sm="5"
        md="2"
        :order="
          $vuetify.breakpoint.smOnly ? 1 : $vuetify.breakpoint.xsOnly ? 1 : ''
        "
        class="py-0"
      >
        <v-text-field
          outlined
          dense
          v-model="filters.search"
          prepend-inner-icon="mdi-magnify"
          class="ml-auto filters search"
          :label="$t('search')"
          hide-details
          single-line
          clearable
          @input="searchFilter"
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        :order="
          $vuetify.breakpoint.smOnly ? 2 : $vuetify.breakpoint.xsOnly ? 3 : ''
        "
        v-if="!$store.getters['auth/isTattooer']"
        class="py-0"
      >
        <v-select
          outlined
          dense
          v-model="filters.tattooer_id"
          :label="$tc('artist')"
          :items="tattooers"
          item-value="id"
          class="filters"
          item-text="name"
          clearable
          hide-details
          single-line
          @input="searchFilter"
          prepend-inner-icon="$tattooers"
          :menu-props="{ offsetY: true }"
        >
          <template slot="selection" slot-scope="data"
            ><span class="no-wrap">
              {{ data.item.user.first_name }} {{ data.item.user.last_name }}
            </span>
          </template>
          <template slot="item" slot-scope="data"
            >{{ data.item.user.first_name }}
            {{ data.item.user.last_name }}</template
          >
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="3"
        lg="2"
        class="py-0"
        :order="
          $vuetify.breakpoint.smOnly ? 4 : $vuetify.breakpoint.xsOnly ? 5 : ''
        "
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              :value="
                filters.dates ? $d(new Date(filters.dates), 'date2digits') : ''
              "
              :label="$tc('expenses.data_appointment')"
              prepend-inner-icon="$appointments"
              class="filters data"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              hide-details
              @click:clear="
                filters.dates = null;
                searchFilter();
              "
            ></v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            no-title
            v-model="filters.dates"
            @input="
              menu = false;
              searchFilter();
            "
            color="primary"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        :order="
          $vuetify.breakpoint.smOnly ? 5 : $vuetify.breakpoint.xsOnly ? 4 : ''
        "
        cols="6"
        sm="4"
        md="2"
        class="py-0"
      >
        <city-select
          :value="filters"
          valueKey="city"
          clearable
          :label="$tc('studios.city')"
          @input="searchFilter"
        />
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col
        cols="4"
        sm="3"
        align="right"
        :order="
          $vuetify.breakpoint.mdAndDown
            ? 2
            : $vuetify.breakpoint.xsOnly
            ? 2
            : ''
        "
        class="buttonCustomer py-0"
      >
        <v-btn
          v-if="$store.getters['auth/isStudio']"
          elevation="0"
          icon
          @click="
            $router.push({
              name: 'UserSettings',
              params: { type: 'alerts' },
            })
          "
        >
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon size="25" v-on="on"> mdi-swap-vertical </v-icon>
              </template>
              {{ $t("exp-imp") }}
            </v-tooltip>
          </template>
        </v-btn>

        <v-btn
          dark
          block
          @click="$router.push({ name: 'customerCreate' })"
          v-if="$store.getters['auth/isAdmin']"
          style="height: 30px; margin-top: 5px"
          elevation="0"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("new", { name: $tc("customer") }) }}
        </v-btn>
        <v-btn
          v-else-if="
            $store.getters['auth/isTattooerMain'] ||
              $store.getters['auth/isStudio']
          "
          elevation="0"
          @click="$router.push({ name: 'customerCreateWithoutDate' })"
          icon
          ><template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon size="36" v-on="on">mdi-plus-circle</v-icon>
              </template>
              {{ $t("new", { name: $tc("customer") }) }}
            </v-tooltip>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <base-data-table-cards
      id="customer_table"
      :headers="headers"
      :headers_small="headers_small"
      :data="items"
      :options.sync="options"
      :server-items-length="totalCustomers"
      :totalItems="totalCustomers"
      :loading="loading"
      :title="$tc('customer', 1)"
      @update="fetchCustomers"
      :sort-by.sync="sortBy"
    >
      <template slot="no_info">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-img max-width="200" src="../../assets/no_customers.png"></v-img>
          </v-col>
          <v-col class="pb-0" cols="12" align="center">
            <h2 class="primary--text">{{ $tc("customer", 2) }}</h2>
          </v-col>
          <v-col class="pt-0" cols="12" align="center">
            <span>{{ $t("create_new_m", { name: $tc("customer", 1) }) }}</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center">
            <v-btn
              v-if="$store.getters['auth/isAdmin']"
              @click="$router.push({ name: 'customerCreate' })"
              >Nuevo Cliente</v-btn
            >
            <v-btn
              v-else-if="
                $store.getters['auth/isTattooerMain'] ||
                  $store.getters['auth/isStudio']
              "
              @click="$router.push({ name: 'customerCreateWithoutDate' })"
              >Nuevo Cliente</v-btn
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:item_email="item">
        <v-tooltip v-if="item.user.email" bottom>
          <template v-slot:activator="{ on }">
            <a
              @click="
                $router.push({
                  name: 'email',
                  params: { email: item.user.email },
                })
              "
              v-on="on"
              v-text="item.user.email"
            />
          </template>
          {{ item.user.email }}
        </v-tooltip>
        <span v-else> - </span>
      </template>

      <template v-slot:item_actions="item" v-if="$vuetify.breakpoint.mdAndUp">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item
              class="cusList"
              @click="
                $router.push({
                  name: 'customerView',
                  params: { id: item.id },
                })
              "
            >
              <v-list-item-icon>
                <v-icon small style="margin-top: -5px !important"
                  >$eye</v-icon
                ></v-list-item-icon
              ><v-list-item-title>
                <span>{{
                  $t("view", { name: $tc("customer") })
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="$store.getters['auth/isStudio'] && false"
              class="cusList"
              @click="
                $router.push({
                  name: 'customer_inscription_studio',
                  params: {
                    token: $store.state.auth.user.studio.token,
                    customer: item,
                  },
                })
              "
            >
              <v-list-item-icon>
                <v-icon small style="margin-top: -4px !important">
                  $request
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("tattooers.inscription") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--
              <v-list-item
                class="cusList"
                @click="
                  $router.push({
                    name: 'customerEdit',
                    params: { id: item.id },
                  })
                "
              >
                <v-list-item-icon>
                  <v-icon small style="margin-top: -5px !important"
                    >$editprofile</v-icon
                  ></v-list-item-icon
                >
                  <v-list-item-title>
                  <span>{{
                    $t("edit", { name: $tc("customer") })
                  }}</span></v-list-item-title
                >
              </v-list-item>
                -->

            <v-dialog width="530" v-model="dialog_new">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-icon class="mr-3 my-md-2 my-2">
                    <v-icon small>$request</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        $t("new", { name: $tc("appointment") })
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title
                  style="
                      text-decoration: underline;
                      text-transform: uppercase;
                    "
                >
                  Tipo de cita
                </v-card-title>

                <div class="close">
                  <v-btn icon @click="dialog_new = false" small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <v-card-text>
                  <v-row justify="center">
                    <template v-for="(s, i) in services">
                      <v-col cols="4" :key="`col-${i}`" class="mx-2">
                        <v-card
                          @click="select(s.name, item.id)"
                          style="border: 1px solid var(--v-primary-base)"
                        >
                          <v-card-title
                            class="mb-2 px-0 d-block text-center"
                            style="
                                font-size: 16px !important;
                                text-decoration: none !important;
                              "
                          >
                            {{ $t("appointments.type." + s.name) }}
                          </v-card-title>

                          <v-avatar
                            color="primary"
                            size="60"
                            class="gris3--text text-h2 text-uppercase mx-auto"
                            style="display: flex"
                          >
                            {{ s.name[0] }}
                          </v-avatar>

                          <v-card-subtitle></v-card-subtitle>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item_actions="item" v-else>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="primary"
              @click="
                $router.push({
                  name: 'customerView',
                  params: { id: item.id },
                })
              "
              fab
              outlined
              dark
              small
              ><v-icon>$eye</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              @click="
                $router.push({
                  name: 'customer_inscription_studio',
                  params: {
                    token: $store.state.auth.user.studio.token,
                    customer: item,
                  },
                })
              "
              fab
              outlined
              dark
              small
              ><v-icon>$request</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item_phone="item">
        <span v-if="item.user.phone == null">-</span>
        <span>
          {{ item.user.phone }}
          <WhatsApp v-if="item.user.phone" :phone_number="item.user.phone" />
        </span>
      </template>
      <template v-slot:item_last_name="item">
        {{ item.user.last_name || "-" }}
      </template>
      <template v-slot:item_fullname_email="item">
        <div col="2">
          <b>{{ item.user.fullname }}</b>
        </div>
        <div>{{ item.user.email }}</div>
      </template>
      <template v-slot:item_avatar="item">
        <v-avatar
          class="ml-4"
          color="primary"
          :size="40"
          style="
                  margin: 0 auto;
                  font-size: 25px;
                  text-transform: uppercase;
                  color: #272727;
                  cursor: pointer;
                "
        >
          <span>{{ nameAvatar(item.user.fullname) }}</span>
        </v-avatar>
      </template>
      <template v-slot:item_menor="item">
        <v-tooltip bottom v-if="menor(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red" dark v-bind="attrs" v-on="on">
              $underage
            </v-icon>
          </template>
          <span>{{ $t("under.age") }}</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" dark v-bind="attrs" v-on="on">
              $underage
            </v-icon>
          </template>
          <span>{{ $t("over.age") }}</span>
        </v-tooltip>
      </template>

      <!--<template v-slot:item_city="item">
          <span v-if="item.city == null">-</span>
          <span v-else>{{
            item.city[0] === "{" ? JSON.parse(item.city).nm : item.city
          }}</span>
        </template>-->
    </base-data-table-cards>
  </base-material-card>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
import * as moment from "moment";

export default {
  name: "CustomerList",

  watch: {
    options: {
      handler() {
        this.fetchCustomers();
      },
      deep: true,
    },
  },
  components: {
    CitySelect: () => import("@/components/ui/CitySelect"),
    Import: () => import("@/components/exp-imp/Import"),
  },
  data() {
    return {
      dialog_new: false,
      headers: [
        {
          text: this.$t("first_name"),
          align: "center",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: this.$t("last_name"),
          align: "center",
          sortable: false,
          value: "last_name",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("phone"),
          align: "center",
          sortable: false,
          value: "phone",
        },
        /*{
          text: this.$t("customers.city"),
          align: "center",
          sortable: false,
          value: "city",
        },*/
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: null,
      filters: {
        search: "",
        dates: "",
        tattooers: "",
      },
      tattooers: [],
      totalCustomers: 0,
      sortBy: "first_name",
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      menu: false,
      types: [],
      itemsServices: [],
      headers_small: [
        { value: "avatar", no_col: true },
        { value: "fullname_email" },
        { value: "phone" },
        { value: "menor", cols: 2 },
      ],
    };
  },

  mounted() {
    if (this.$store.getters["auth/isTattooer"]) {
      this.filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
    } else if (this.$store.getters["auth/isStudio"]) {
      this.filters.studio_id = this.$store.state.auth.user.studio.id;
    }

    this.fetchCustomers();
    this.fetchCustomersFilters();
    if (this.$store.getters["auth/isTattooer"]) this.fetchTypes();
  },
  computed: {
    services() {
      if (this.$store.getters["auth/isStudio"])
        return JSON.parse(
          this.$store.getters["auth/getSetting"]("studio_services")
        );
      else return this.types;
    },
  },
  methods: {
    menor(item) {
      let menor = false;
      let today = moment();
      let birthdate = moment(item.birthdate);
      let edad = today.diff(birthdate, "years");
      console.log(edad);
      if (edad < 18) {
        menor = true;
      }
      return menor;
    },

    nameAvatar(item) {
      //console.log(arr);
      let arr = item.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });

      return nom;
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      console.log("fetch");
      this.getTypeArtist().then((tattooers) => {
        ////console.log(tattooers);
        this.itemsServices = tattooers.data;

        if (this.$store.state.auth.user.types[0].name) {
          this.types = this.$store.state.auth.user.types;
        } else {
          console.log("NO OBJECT");
          this.types = this.$store.state.auth.user.types.map((q) => {
            return this.itemsServices.find((x) => x.id === q);
          });
        }
      });
    },
    ...mapActions("customers", ["getCustomers", "getCustomerFilters"]),

    fetchCustomers() {
      this.loading = true;
      console.log(this.filters.city);
      if (typeof this.filters.city === "object")
        this.filters.city = this.filters.city.nm;
      this.getCustomers({
        pagination: this.options,
        filters: this.filters,
      }).then((customers) => {
        this.items = customers.data;
        //console.log(this.items);
        this.totalCustomers = customers.total;
        this.loading = false;
      });
    },
    fetchCustomersFilters() {
      this.loading = true;
      this.getCustomerFilters().then((filters) => {
        this.dates = filters.dates;
        this.tattooers = filters.tattooers;
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchCustomers();
    }, 1000),
    ...mapActions("appointments", ["internalForm"]),
    select(type, customer) {
      this.internalForm({
        studio_id: this.$store.getters["auth/isStudio"]
          ? this.$store.state.auth.user.studio.id
          : -1,
        type,
        customer,
      }).then((response) => {
        if (response.success)
          this.$router.push({
            name: "appointmentView",
            params: { id: response.id },
          });
      });
    },
  },
};
</script>
<style lang="sass">
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-application .col-6
  //padding-right: 12px !important
.data
  label
    min-width: 75px !important
.cusList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important

.cusList .v-icon, .cusList .svg-icon
  margin-top: -3px
  color: var(--v-gris1-lighten2)
#customer-list

  @media (min-width: 800px)
    .shorter
      max-width: 170px

#customer_table
  .v-data-table__mobile-row__cell
    max-width: 175px !important
  td
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3)
    max-width: 20px
@media (max-width: 500px)
  #customer_table
    .v-data-table__mobile-row__header
      min-width: 100px !important
      text-align: left
    td
      max-width: 100% !important

.v-card--material .v-image
  box-shadow: none
</style>
